import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './pages/App';

import TagManager from 'react-gtm-module';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const tagManagerArgs = {
  gtmId: "GTM-THVQ9NBW",
};

TagManager.initialize(tagManagerArgs);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);