import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InstagramIcon from '@mui/icons-material/Instagram'
import WhatsApp from '@mui/icons-material/WhatsApp'

import mi from '../../resources/images/mi_whats.png'
import ju from '../../resources/images/ju_whats.png'
import wi from '../../resources/images/wi_whats.png'
import { green, orange } from '@mui/material/colors';
import {  sendInstagram, TypeImage} from '../../resources/ts/services';

export default function Instagram({linkWhats, typeImage} : any) {

   let image = ""

   if (typeImage === TypeImage.wi) {
      image = wi 
   } else if (typeImage === TypeImage.ju) {
      image = ju
   } else  {
      image = mi 
   }

   return (
      <Card sx={{ maxWidth: {md:645, xs: 345}, marginBottom: 5 }}>
         <CardMedia
            sx={{ 
               height: 140,   
            }}
            image={image}
            title="bg glaucar"
         />
         <CardContent>
            <Typography gutterBottom variant="h5" component="div">
               HM Guincho
            </Typography>
            <Typography variant="body2" color="text.secondary">
               Acompanhe de perto nosso trabalho acessando nossas
               redes sociais disponíveis
            </Typography>
         </CardContent>
         <CardActions>
            <Button size="small"
               style={{  cursor: 'pointer', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}
               onClick={sendInstagram}
            >
               <a href='https://www.instagram.com/hmguinchos/' style={{ textDecoration: 'none', display: 'flex' }} >
                  Instagram 
                  <InstagramIcon sx={{
                     ml:1,
                     color: orange[900]
                  }} />
               </a>
            </Button>
            <Button size="small" 
               style={{  cursor: 'pointer', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}
            >
            <a href={linkWhats} style={{ textDecoration: 'none', display: 'flex' }} >
               Whatsapp

                  <WhatsApp sx={{
                     ml:1,
                     color: green[900]
                  }} />
            </a>
            </Button>
         </CardActions>
      </Card>
   );
}
