import {
   Box,
   Typography
} from "@mui/material"

import './mainSection.css'
import { colors } from "../../template/colors"

export const MainSection = () => {
   return (
      <div id="main-section">
         <Box
            className='section-container'
         >
            <div style={{
               position: 'absolute',
               top: 0,
               left: 0,
               width: '100%',
               height: '100%',
               backgroundColor: 'rgba(0, 0, 0, 0.8)',
               textAlign: 'center',
               alignContent: 'center',
               display: 'flex',
               flexDirection: 'column',
               justifyContent: 'center',
            }}>
                
               <Typography
                  variant="body1"
                  component="a"
                  alignSelf='center'
                  width='70%'
                  sx={{
                     p: '10px',
                     borderRadius: 10,
                     color: 'white',
                     backgroundColor: colors.bg_red_trans,
                     fontSize: 'calc(10px + 2vw)',
                     mt: '60px'
                  }}
               >
                  HM GUINCHO BUSCA IMEDIATA
               </Typography>
               <Typography variant="body1" color='#bdbdbd' mt={6} p={2}
                  sx={{
                     fontSize:{ xs: 15, md: 20 }
                  }}
               >
                  Atendimento rápido, seguro e mais econômico da região.
                  Fazemos viagens na região de Cotia, Vargem Grande Paulista, Caucaia do Alto e Osasco.

               </Typography>
               <Typography variant="body1" color='#bdbdbd' mt={1} p={2}
                  sx={{
                     fontSize:{ xs: 15, md: 20 }
                  }}
               >
                  Mais de um guincho para garantir a rapidez do atendimento. 

                  Entre em contato através dos números: (11) 99326-9784 / (11) 99731-6569 / (11) 91512-1991
               </Typography>

            </div>
         </Box>
      </div>
   )
}