import Instagram from "../../components/instagram/Instagram"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { getJuniorWhats, getMichelWhats, getWillianWhats, TypeImage } from "../../resources/ts/services";

export const Contact = () => {

   return (
      <div
         id='contacts'
         style={{
            marginTop: 90,
            marginBottom: 90,
            justifyContent: 'center',
            display: 'flex'
         }}
      >
         <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={1}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            autoplay={{ delay: 9000 }}
            style={{
               borderRadius: '8px',
               boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
            }}
         >
            <SwiperSlide style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
               <Instagram linkWhats={getMichelWhats()} typeImage={TypeImage.mi} />
            </SwiperSlide>
            <SwiperSlide style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
               <Instagram linkWhats={getJuniorWhats()} typeImage={TypeImage.ju} />
            </SwiperSlide>
            <SwiperSlide style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
               <Instagram linkWhats={getWillianWhats()} typeImage={TypeImage.wi} />
            </SwiperSlide>
         </Swiper>
      </div>
   )
}
