import wts from '../../resources/images/whatsapp.png'
import { sendWhatsapp, getUrlWhatsapp } from '../../resources/ts/services'


export const Wathsapp = () => {

   var url = getUrlWhatsapp();

   return (
      <div style={{
         position: 'fixed',
         zIndex: 2000,
         bottom: 30,
         right: 10,
         background: 'none',
         border: 'none',
         cursor: 'pointer',
         textDecoration: 'none'
      }}
         onClick={sendWhatsapp}
      >
         <a href={url} >
            <img src={wts} height={70} alt='whats to click' />
         </a>
      </div>
   )
}