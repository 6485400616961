
export function sendWhatsapp() {
   window.open(getUrlWhatsapp(), '_blank');
}

export function getUrlWhatsapp() {
   const numeroTelefone = '+5511993269784';
   const mensagem = 'Olá, gostaria de solicitar um serviço.';
   const url = `https://api.whatsapp.com/send?phone=${numeroTelefone}&text=${encodeURIComponent(mensagem)}`;
   return url;
}

export function getMichelWhats():string {
   const numeroTelefone = '+5511993269784';
   const mensagem = 'Olá, gostaria de solicitar um serviço.';
   const url = `https://api.whatsapp.com/send?phone=${numeroTelefone}&text=${encodeURIComponent(mensagem)}`;
   return url;
}

export function getWillianWhats() {
   const numeroTelefone = '+5511997316569';
   const mensagem = 'Olá, gostaria de solicitar um serviço.';
   const url = `https://api.whatsapp.com/send?phone=${numeroTelefone}&text=${encodeURIComponent(mensagem)}`;
   return url;
}

export function getJuniorWhats() {
   const numeroTelefone = '+5511915121991';
   const mensagem = 'Olá, gostaria de solicitar um serviço.';
   const url = `https://api.whatsapp.com/send?phone=${numeroTelefone}&text=${encodeURIComponent(mensagem)}`;
   return url;
}


export function sendInstagram() {
   const url = `https://www.instagram.com/hmguinchos/`;
   window.open(url, '_blank');
}

export enum TypeImage {
   mi = 'mi',
   ju = 'ju',
   wi = 'wi'
}